import React from 'react'
import {CircularProgress} from '@material-ui/core'
import styled from 'styled-components'

const LoadingContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`

const Loading = () => {
	return (
		<LoadingContainer>
			<CircularProgress />
		</LoadingContainer>
	)
}

export default Loading
