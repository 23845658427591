import React, {lazy, Suspense} from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import {AuthProvider} from './helpers/authContext'
import Home from './components/Home'
import ErrorBoundary from './components/ErrorBoundary'
import Loading from './components/Loading'
const TopBar = lazy(() => import('./components/TopBar'))
const Login = lazy(() => import('./components/Login'))
const Swagger = lazy(() => import('./components/Swagger'))
const SwaggerFunctions = lazy(() => import('./components/Swagger-Functions'))
const Queue = lazy(() => import('./components/Queue'))
const Little = lazy(() => import('./components/Little'))

const theme = createMuiTheme({
	palette: {
		background: {
			default: '#feeee2',
		},
	},
})

function App() {
	return (
		<Router>
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				<AuthProvider>
					<ErrorBoundary>
						<Suspense fallback={<Loading />}>
							<TopBar/>
							<Switch>
								<Route exact path="/">
									<Home />
								</Route>
								<Route path="/login">
									<Login />
								</Route>
								<Route path="/swagger">
									<Swagger />
								</Route>
								<Route path="/swagger-functions">
									<SwaggerFunctions />
								</Route>
								<Route path="/queue">
									<Queue />
								</Route>
								<Route path="/little">
									<Little />
								</Route>
							</Switch>
						</Suspense>
					</ErrorBoundary>
				</AuthProvider>
			</MuiThemeProvider>
		</Router>
	)
}

export default App
