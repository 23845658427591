import React from 'react'
import styled from 'styled-components'

const MainContainer = styled.div`
    display: flex;
    justify-content: center;
`

const Home = () => {
	return (
		<MainContainer>
				<img
					src="images/crossroads-architecture.png"
					alt="Crossroads Architecture"
					height={window.innerHeight * 1.1}
					width="auto"
				/>
		</MainContainer>
	)
}
export default Home
