import React from 'react'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {hasError: false}
	}

	static getDerivedStateFromError(error) {
		return {hasError: true, error}
	}

	// componentDidCatch(error, errorInfo) {
	// 	this.setState({
	// 		hasError: true,
	// 		error,
	// 	})
	// 	console.log('Its an error lol', error)
	// }

	render() {
		const {hasError} = this.state

		if (hasError) {
			return <h1>Something went wrong.</h1>
		}

		return this.props.children
	}
}

export default ErrorBoundary
